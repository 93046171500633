



































































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import TimelineBuckets from '../components/TimelineBuckets.vue'
import ITemplate from '@/areas/templates/models/ITemplate'
import TemplateSelect from '@/areas/templates/components/TemplateSelect.vue'
import ITimeline from '../models/ITimeline'

const { mapGetters, mapActions } = createNamespacedHelpers('ProductEndDates')

export default Vue.extend({
  components: {
    TemplateSelect,
    TimelineBuckets
  },
  data() {
    return {
      toggleNone: null,
      dateFormat: null,
      templateId: null,
      headers: [
        {
          text: 'Product',
          align: 'start',
          value: 'listingName',
          groupable: false
        },
        {
          text: 'End Date',
          value: 'endDate',
          groupable: false
        }
      ],
      generating: false,
      loadingText: 'Loading...',
      timelines: null,
      customerTimeline: false,
      tpid: null,
      orgIds: null
    }
  },
  computed: {
    ...mapGetters(['year', 'results', 'enteringExtendedSupport', 'loading', 'years', 'supportPolicies', 'supportPolicy']),
    error(): Error {
      return null
    }
  },
  methods: {
    ...mapActions({
      exportTimeline: 'exportTimeline'
    }),
    async load() {
      const tpid = +this.$route.query.tpid
      const orgIds = this.$route.query.orgIds
      if (!isNaN(tpid)) {
        this.tpid = tpid
        if (Array.isArray(orgIds)) {
          this.orgIds = orgIds
        } else {
          this.orgIds = orgIds.split(',')
        }
        this.customerTimeline = true
        const template = this.$store.getters['Templates/default'] as ITemplate
        this.templateId = template.id
        const customer = await this.$store.dispatch('Customers/byTpid', tpid)
        // get tpid level products first
        let products = await this.$store.dispatch('ProductEndDates/load', { tpid, orgId: 0 })
        let enteringExtendedSupport = await this.$store.dispatch('ProductEndDates/loadEnteringExtendedSupport', { tpid, orgId: 0 })
        this.timelines = [{
          customerName: customer.name,
          customerId: customer.tpid,
          products,
          enteringExtendedSupport
        } as ITimeline]
        // get products for each sub org
        for (let i = 0; i < this.orgIds.length; i++) {
          const orgId = +this.orgIds[i]
          if (isNaN(orgId) || orgId === 0) {
            continue
          }
          const org = customer.customerOrganizations.find(x => x.organizationId === orgId)
          products = await this.$store.dispatch('ProductEndDates/load', { tpid, orgId })
          enteringExtendedSupport = await this.$store.dispatch('ProductEndDates/loadEnteringExtendedSupport', { tpid, orgId })
          this.timelines.push({
            customerName: org.name,
            customerId: org.organizationId,
            products,
            enteringExtendedSupport
          } as ITimeline)
        }
      } else {
        this.tpid = NaN
        this.customerTimeline = false
        const template = await this.$store.dispatch('Templates/getByLocale', 'en-US') as ITemplate
        this.templateId = template.id
        const products = await this.$store.dispatch('ProductEndDates/load', { tpid: 0, orgId: 0 })
        const enteringExtendedSupport = await this.$store.dispatch('ProductEndDates/loadEnteringExtendedSupport', { tpid: 0, orgId: 0 })
        this.timelines = [{
          products,
          enteringExtendedSupport
        } as ITimeline]
      }
    },
    async generatePdf() {
      this.loadingText = 'Exporting Timeline...'
      this.generating = true
      try {
        const template = this.$store.getters['Templates/getById'](this.templateId) as ITemplate
        await this.exportTimeline({
          tpid: this.tpid,
          orgIds: this.orgIds,
          locale: template.dmclanguageCode,
          format: 'application/pdf'
        })
      } finally {
        this.generating = false
        this.loadingText = 'Loading...'
      }
    },
    async generatePptx() {
      this.loadingText = 'Exporting Timeline...'
      this.generating = true
      try {
        const template = this.$store.getters['Templates/getById'](this.templateId) as ITemplate
        await this.exportTimeline({
          tpid: this.tpid,
          orgIds: this.orgIds,
          locale: template.dmclanguageCode,
          format: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        })
      } finally {
        this.generating = false
        this.loadingText = 'Loading...'
      }
    },
    async generateHtml() {
      this.loadingText = 'Exporting Timeline...'
      this.generating = true
      try {
        const template = this.$store.getters['Templates/getById'](this.templateId) as ITemplate
        await this.exportTimeline({
          tpid: this.tpid,
          orgIds: this.orgIds,
          locale: template.dmclanguageCode,
          format: 'text/html'
        })
      } finally {
        this.generating = false
        this.loadingText = 'Loading...'
      }
    }
  },
  async created() {
    await this.load()
  },
  watch: {
    $route: {
      handler() {
        this.load()
      }
    }
  }
})
