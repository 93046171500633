






















































































import Vue from 'vue'
import { groupBy } from 'lodash-es'
import IProductEndDate from '../models/IProductEndDate'
import ITimeline from '../models/ITimeline'
import ProductList from '../components/ProductList.vue'

export default Vue.extend({
  props: {
    loading: Boolean,
    timeline: Object as () => ITimeline
  },
  components: {
    ProductList
  },
  data() {
    return {
    }
  },
  computed: {
    resultsGroupedByDay() {
      return groupBy(this.timeline.products, x => {
        return x.endDate
      })
    },
    productsOutOfSupport() : IProductEndDate[] {
      return this.productsFilter(-13, -1)
    },
    products0to6() : IProductEndDate[] {
      return this.productsFilter(0, 6)
    },
    productsExt0to6() : IProductEndDate[] {
      return this.productsExtFilter(0, 6)
    },
    products7to12() : IProductEndDate[] {
      return this.productsFilter(6, 12)
    },
    productsExt7to12() : IProductEndDate[] {
      return this.productsExtFilter(6, 12)
    },
    products13to24() : IProductEndDate[] {
      return this.productsFilter(12, 24)
    },
    productsExt13to24() : IProductEndDate[] {
      return this.productsExtFilter(12, 24)
    },
    products25to36() : IProductEndDate[] {
      return this.productsFilter(24, 36)
    },
    productsExt25to36() : IProductEndDate[] {
      return this.productsExtFilter(24, 36)
    }
  },
  methods: {
    productsFilter(start, end): IProductEndDate[] {
      const startDate = this.getStartDateFrom(start)
      const endDate = this.getEndDateFrom(end)
      return this.timeline.products.filter(x => {
        const listingDate = new Date(x.endDate)
        return listingDate >= startDate && listingDate <= endDate
      })
    },
    productsExtFilter(start, end): IProductEndDate[] {
      const startDate = this.getStartDateFrom(start)
      const endDate = this.getEndDateFrom(end)
      return this.timeline.enteringExtendedSupport.filter(x => {
        const listingDate = new Date(x.endDate)
        return listingDate >= startDate && listingDate <= endDate
      })
    },
    getStartDateFrom(monthsFromToday: number) : Date {
      const today = new Date()
      const date = new Date(today.getFullYear(), today.getMonth() + monthsFromToday, 1)
      return date
    },
    getEndDateFrom(monthsFromToday: number) : Date {
      const today = new Date()
      const date = new Date(today.getFullYear(), today.getMonth() + monthsFromToday, 0)
      return date
    }
  }
})
